.root {
  gap: 12px;
  width: 153px;
  height: 214px;
  padding: 16px;
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  position: relative;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e8e8ef;
  background: rgba(255, 255, 255, 0.8);

  .iconWrapper {
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .infoWrapper {
    gap: 4px;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .ellipsis1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .ellipsis2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .actionButton {
    width: 100%;
    margin-top: auto;
  }
}
