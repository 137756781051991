@import "@assets/css/media-mixin.scss";

.root {
  display: grid;
  grid-template-columns: 253px auto;
  grid-column-gap: 24px;
  font-family: 'Noto Sans', serif;

  @include respond-to("md") {
    grid-template-columns: 190px auto;
  }
}
