.select {
  flex-grow: 1;
  max-width: 400px;
  position: relative;
  -webkit-user-select: none;
          user-select: none;

  &.disabled .selectHeader {
    pointer-events: none;
    background-color: #e8e8ef;
  }

  .selectHeader {
    display: flex;
    justify-content: space-between;
    gap: 3px;
    align-items: center;
    padding: 4px 6px 4px 12px;
    height: 24px;
    border: 1px solid #e8e8ef;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;

    .activeOption {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .selectOptions {
    z-index: 100;
    width: 100%;
    position: absolute;
    box-shadow: 0px 2px 20px 0px #0000001a;
    max-height: 150px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background: #d6d6df;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #b4b4bc;
    }
  }

  .selectOption {
    font-size: 12px;
    background-color: white;
    padding: 4px 6px 4px 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      background-color: #e8e8ef;
    }

    &.active {
      background-color: #e8e8ef;
    }
  }

  .rotateArrow {
    transform: rotate(180deg);
  }
}
