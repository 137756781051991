.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100%;
  min-width: 500px;
  height: 64px;
  border: 1px solid #e8e8ef;
  border-radius: 8px;

  .info {
    display: flex;
    align-items: center;
  }

  .iconWrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
      margin-right: 12px;
    }
  }

  .buttons {
    gap: 8px;
    display: flex;
    align-items: center;
  }

  .button {
    width: 94px;
  }
}

.deleting {
  opacity: 0.5;
}
