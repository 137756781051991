// Noto Sans regular
@font-face {
  font-family: "Noto Sans";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/NotoSans.eot");
  src: url("../fonts/NotoSans.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans.woff2") format("woff2"),
    url("../fonts/NotoSans.woff") format("woff"),
    url("../fonts/NotoSans.ttf") format("truetype"),
    url("../fonts/NotoSans.svg#NotoSans") format("svg");
}

// Noto Sans regular italic
@font-face {
  font-family: "Noto Sans";
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/NotoSans-Italic.eot");
  src: url("../fonts/NotoSans-Italic.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans-Italic.woff2") format("woff2"),
    url("../fonts/NotoSans-Italic.woff") format("woff"),
    url("../fonts/NotoSans-Italic.ttf") format("truetype"),
    url("../fonts/NotoSans-Italic.svg#NotoSans-Italic") format("svg");
}

// Noto Sans bold
@font-face {
  font-family: "Noto Sans";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/NotoSans-Bold.eot");
  src: url("../fonts/NotoSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/NotoSans-Bold.woff2") format("woff2"),
    url("../fonts/NotoSans-Bold.woff") format("woff"),
    url("../fonts/NotoSans-Bold.ttf") format("truetype"),
    url("../fonts/NotoSans-Bold.svg#NotoSans-Bold") format("svg");
}
