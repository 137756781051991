.root {
  display: flex;
  height: 100vh;
  padding: 16px 0 16px 0;
  flex-direction: column;
  background: #e8e8ef;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .wrapper {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .bottomListWrapper {
    padding: 0 16px;
  }

  .bottomList {
    padding: 8px;
    border-radius: 8px;
    background-color: #ffffff;

    & a {
      border-radius: 8px;
    }
  }
}
