.root {
  gap: 8px;
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  padding: 7px 16px;
  align-items: center;
  box-sizing: content-box;

  svg {
    fill: #101025;
  }

  &:not(.linkActive):hover {
    background-color: #93cee938;
    color: #101025;
    svg {
      fill: #101025;
    }
  }
}

.linkActive {
  background: #1d99f3;
  color: white;

  svg {
    fill: #ffffff;
  }

  &:hover {
    background: #1d99f3;
    color: white;

    svg {
      fill: #ffffff;
    }
  }
}
