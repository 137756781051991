.root {
  gap: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .header {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .headerContainer {
    gap: 8px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .contentWrapper {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
