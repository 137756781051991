.root {
  display: flex;
  height: 100vh;
  padding: 24px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 22px;
    height: 223px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d6d6df;
    border: 6px solid white;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b4b4bc;
  }
}
