.root {
  gap: 8px;
  height: 56px;
  padding: 12px;
  display: flex;
  cursor: pointer;
  min-width: 318px;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e8e8ef;

  .iconWrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .info {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
