.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  outline: none;
  width: 500px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(41, 41, 64, 0.06),
    0px 1px 3px 0px rgba(41, 41, 64, 0.08);
}

.header {
  height: 48px;
  display: flex;
  padding: 12px;
  align-items: center;
  background: #f4f4f8;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8ef;
}

.icon {
  width: 64px;
  height: 64px;
}

.content {
  gap: 30px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
}

.info {
  gap: 12px;
  display: flex;
  align-items: center;
}

.buttons {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
