@import "@assets/css/media-mixin.scss";

.root {
  gap: 16px;
  display: flex;
  padding: 24px;
  border-radius: 8px;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: linear-gradient(0deg, #ffecd2 0%, #fcb69f 100%);

  .headerContainer {
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .headerInfo {
    gap: 1px;
    display: flex;
    flex-direction: column;
  }

  .watchAllButton {
    width: 68px;
    padding: 4px 11px;
  }

  .cardsContainer {
    gap: 10px;
    display: flex;
    justify-content: flex-start;
    & > *:nth-child(n + 8) {
      display: none;
    }

    @include respond-to("xxl") {
      & > *:nth-child(n + 6) {
        display: none;
      }
    }

    @media (max-width: "1200px") {
      & > *:nth-child(n + 5) {
        display: none;
      }
    }

    @include respond-to("lg") {
      & > *:nth-child(n + 4) {
        display: none;
      }
    }

    @media (max-width: "850px") {
      & > *:nth-child(n + 3) {
        display: none;
      }
    }

    @media (max-width: "620px") {
      justify-content: center;
      & > *:nth-child(n + 2) {
        display: none;
      }
    }
  }
}
