.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .content {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .checkUpdatesButton {
    background: "none";
    color: "#1D99F3";
  }
}
