@import "@assets/css/media-mixin.scss";

.root {
  gap: 12px;
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
