.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 20px;
  background: #ffffff;
  border: 1px solid #d6d6df;
  box-shadow: 0px 1px 2px rgba(41, 41, 64, 0.07);
  border-radius: 4px;

  svg {
    display: block;
    width: 100%;
    color: #87879B;
  }
}
