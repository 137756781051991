.root {
  flex-grow: 1;
  display: flex;
  min-height: 304px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: linear-gradient(180deg, #1d99f3 0%, #c2e9fb 100%);

  .bannerDescription {
    gap: 8px;
    width: 280px;
    display: flex;
    padding: 24px;
    color: #ffffff;
    text-decoration: none;
    flex-direction: column;
  }
}
