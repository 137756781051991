.root {
  display: flex;
  flex-direction: column;
  background: #fbfbfc;
  border: 1px solid #e8e8ef;
  border-radius: 10px;

  .row {
    display: flex;
    position: relative;

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #e8e8ef;
    }

    .appInfoWrapper {
      display: flex;
      padding: 12px;
      position: relative;
      gap: 3px;
      flex: 1 1 0px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e8e8ef;
      }
    }
  }

  .link {
    cursor: pointer;
    &:hover {
      color: #228be6;
    }
  }
}
