.root {
  gap: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .header {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .filters {
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .filtersWrapper {
    gap: 8px;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .select {
    width: 232px;
    margin-left: auto;
  }

  .contentWrapper {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .recommendation {
    padding: 0;
    background-image: none;
  }
}
