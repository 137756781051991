.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;

  .header {
    display: flex;
    gap: 20px;
    align-items: center;

    span {
      font-weight: 800;
      font-size: 20px;
      line-height: 28px;
    }

    .select {
      width: 232px;
      margin-left: auto;
    }
  }

  .content {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
