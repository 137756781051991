.root {
  gap: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .screenshot {
    height: 227px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .rating {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .appInfo {
    display: flex;
    flex-direction: column;
    background: #fbfbfc;
    border: 1px solid #e8e8ef;
    border-radius: 10px;
  }

  .section {
    display: flex;
  }

  .appInfoWrapper {
    display: flex;
    padding: 12px;
    position: relative;
    gap: 3px;
    flex: 1 1 0px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background-color: #e8e8ef;
    }
  }
}
