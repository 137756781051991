.root {
  gap: 20px;
  display: flex;
  flex-direction: column;

  .appMain {
    display: flex;
    height: 100px;
  }

  .logo {
    width: 100px;
    margin-right: 16px;
    img {
      width: 100%;
    }
  }

  .appInfo {
    gap: 3px;
    display: flex;
    flex-direction: column;
  }

  .button {
    width: 164px;
    margin-top: auto;
  }

  .appInfoContainer {
    display: flex;
    background: #fbfbfc;
    border: 1px solid #e8e8ef;
    border-radius: 10px;

    .appInfoWrapper {
      display: flex;
      padding: 8px;
      position: relative;
      gap: 3px;
      flex: 1 1 0px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;

      .categoriesWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }

      &:not(:last-child)::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #e8e8ef;
      }

      .title {
        font-size: 10px;
        line-height: 14px;
        position: relative;
        text-transform: uppercase;
        color: #53546e;
        text-align: center;
      }

      .value {
        font-size: 14px;
        font-weight: 900;
        text-align: center;
      }

      .rating {
        font-size: 16px;
        padding: 3px 0px;
      }

      .unit {
        font-size: 12px;
        line-height: 16px;
        color: #101025;
        text-align: center;
      }
    }

    .line {
      width: 1px;
      height: auto;
      background: #e8e8ef;
      border-radius: 2px;
    }
  }

  .developerWebsite {
    cursor: pointer;
    &:hover {
      color: #228be6;
    }
  }
}
