.root {
  gap: 12px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  width: 320px;
  height: 72px;

  border: 1px solid #e8e8ef;
  border-radius: 8px;
  padding: 16px;

  .iconWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  .blockAboutApp {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .actionButton {
    width: 100px;
  }
}
