.root {
  gap: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8ef;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cards {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & > *:nth-child(n + 6) {
      display: none;
    }

    @media (max-width: 2000px) {
      & > *:nth-child(n + 5) {
        display: none;
      }
    }

    @media (max-width: 1646px) {
      & > *:nth-child(n + 4) {
        display: none;
      }
    }

    @media (max-width: 1279px) {
      & > * {
        flex-grow: 1;
        max-width: 430px;
      }
      & > *:nth-child(n + 3) {
        display: none;
      }
    }
  }
}
