.active {
  color: #1d99f3;

  svg {
    fill: #1d99f3;
  }
}

.disabled {
  pointer-events: none;
  color: #89898c;

  svg {
    fill: #89898c;
  }
}
