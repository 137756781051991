$breakpoints: (
  "xs": 350px,
  "sm": 576px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1440px,
  "xxl": 1600px,
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
