.deletingIcon {
  & svg {
    fill: #fa52527a;
  }
}

.progress {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px !important;
  position: absolute !important;
}
