.screenshot {
  width: 100%;
  height: 227px;
  object-fit: cover;
  cursor: pointer;
}

.lightbox {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    outline: none;
    visibility: hidden;
    pointer-events: none;
  }
}
