.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;

  .header {
    gap: 20px;
    display: flex;
    align-items: center;
  }

  .select {
    width: 232px;
    margin-left: auto;
  }

  .content {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
