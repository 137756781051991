.root {
  padding: 0 16px 16px 16px;

  &.active {
    .wrapper {
      border-color: #1d99f3;

      .searchIcon {
        display: none;
      }

      .sendIcon {
        display: block;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid #d6d6df;
    border-radius: 4px;
    padding: 0 2px 0 8px;
    height: 36px;

    .sendIcon {
      display: none;
      cursor: pointer;
    }

    .sendIcon.disable {
      cursor: default;
      filter: grayscale(1);
      pointer-events: none;
    }

    .searchInput {
      border: 0;
      outline: none;
      width: 100%;
      font-size: 12px;
      font-weight: 400;
    }
  }
}
