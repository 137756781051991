@import "@assets/css/media-mixin.scss";

.root {
  gap: 20px;
  display: flex;
  flex-direction: column;

  .container {
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    grid-template-columns: repeat(3, 1fr);

    a {
      text-decoration: none;
    }

    @media (max-width: "1279px") {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: "930px") {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
